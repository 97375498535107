@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --white: 0 0% 100%;

    --chart-1: 32, 100%, 69%;
    --chart-2: 277, 100%, 65%;

    --primary-1: 210 16% 98%;
    --primary-5: 220 16% 96%;
    --primary-10: 230 17% 93%;
    --primary-50: 231 18% 86%;
    --primary-100: 230 17% 79%;
    --primary-150: 231 17% 75%;
    --primary-200: 230 16% 71%;
    --primary-300: 230 17% 57%;
    --primary-400: 230 22% 43%;
    --primary-500: 230 42% 28%;
    --primary-600: 230 41% 23%;
    --primary-700: 230 43% 17%;
    --primary-800: 230 41% 11%;
    --primary-900: 231 45% 6%;

    --secondary-2: 165 67% 99%;
    --secondary-5: 167 53% 97%;
    --secondary-8: 167 54% 95%;
    --secondary-10: 167 56% 94%;
    --secondary-20: 166 54% 87%;
    --secondary-50: 165 55% 81%;
    --secondary-100: 166 54% 74%;
    --secondary-200: 166 54% 68%;
    --secondary-300: 165 55% 62%;
    --secondary-400: 165 57% 49%;
    --secondary-500: 166 97% 36%;
    --secondary-600: 170 94% 34%;
    --secondary-700: 169 94% 25%;
    --secondary-800: 170 95% 17%;
    --secondary-900: 168 95% 8%;

    --destructive-50: 0 86% 97%;
    --destructive-100: 0 93% 94%;
    --destructive-200: 0 96% 89%;
    --destructive-300: 0 94% 82%;
    --destructive-400: 0 91% 71%;
    --destructive-500: 0 84% 60%;
    --destructive-600: 0 72% 51%;
    --destructive-700: 0 74% 42%;
    --destructive-800: 0 70% 35%;
    --destructive-900: 0 63% 31%;

    --warn-50: 33 100% 96%;
    --warn-100: 34 100% 92%;
    --warn-200: 32 98% 83%;
    --warn-300: 31 97% 72%;
    --warn-400: 27 96% 61%;
    --warn-500: 25 95% 53%;
    --warn-600: 21 90% 48%;
    --warn-700: 17 88% 40%;
    --warn-800: 15 79% 34%;
    --warn-900: 15 75% 28%;

    --neutral-50: 210 20% 98%;
    --neutral-100: 220 14% 96%;
    --neutral-200: 220 13% 91%;
    --neutral-300: 216 12% 84%;
    --neutral-400: 218 11% 65%;
    --neutral-500: 220 9% 46%;
    --neutral-600: 215 14% 34%;
    --neutral-700: 217 19% 27%;
    --neutral-800: 215 28% 17%;
    --neutral-900: 221 39% 11%;

    --success-50: 138 76% 97%;
    --success-100: 141 84% 93%;
    --success-200: 141 79% 85%;
    --success-300: 142 77% 73%;
    --success-400: 142 69% 58%;
    --success-500: 142 71% 45%;
    --success-600: 142 76% 36%;
    --success-700: 142 72% 29%;
    --success-800: 143 64% 24%;
    --success-900: 144 61% 20%;

    /* override schadcn config */
    --background: 240 14.29% 98.63%;
    --foreground: var(--primary-900);

    --primary: var(--primary-500);
    --primary-foreground: var(--primary-1);

    --secondary: var(--primary-5);
    --secondary-foreground: var(--primary-800);

    --muted: var(--neutral-100);
    --muted-foreground: var(--primary-200);

    --accent: var(--neutral-100);
    --accent-foreground: var(--primary-800);

    --success: var(--success-500);
    --success-foreground: var(--success-50);

    --destructive: var(--destructive-500);
    --destructive-foreground: var(--destructive-50);

    --warn: var(--warn-500);
    --warn-foreground: var(--warn-50);

    --popover: var(--white);

    --border: var(--neutral-200);
    --input: var(--neutral-200);

    --card: var(--white);
    --card-foreground: var(--primary-10);

    --ring: var(--primary-900);

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
